import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import styled from "@emotion/styled";
import { createTheme } from "@mui/material/styles";
import "./ContactUs.scss";
// import ReCAPTCHA from 'react-google-recaptcha';
import { handler } from "../../recaptcha/config";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const theme = createTheme();

function ContactUs() {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [captchaValue, setCaptchaValue] = useState(false);

  const sendEmail = () => {
    // if (!token) {
    //   alert("Please verify you are not a robot.");
    //   return;
    // }

    const data = {
      first_name: fName,
      last_name: lName,
      phone: Phone,
      email: Email,
      message: Message,
    };
    emailjs
      .send("service_0lzuqa1", "template_sigy042", data, "j0_DN92CtLP2VlWRd")
      .then(
        (result) => {
          if (result?.status === 200 || result?.status === 201) {
            alert("Sending email successfully");
            setfName("");
            setlName("");
            setPhone("");
            setEmail("");
            setMessage("");
            setCaptchaValue(null); // Reset captcha after successful submissionss
          } else {
            alert("Error Sending Email");
          }
        },
        (error) => {
          alert("Error Sending Email");
        }
      );
  };

  const recaptchaHandler = async () => {
    setCaptchaValue(true);
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf2xKUpAAAAAE4bM_hBiPmTUinM6cpk1zcxuBX-" className="recaptchaContainer">
      <Container className="contactUsContainer">
        <div className="contactUsTitle">
          <br />
          <Typography variant={window.innerWidth > 900 ? "h3" : "h4"}>
            Contact Us
          </Typography>
          <br />
        </div>
        <div className="ContentContainer">
          <Grid
            container
            padding={{ xxl: "10", xl: "10", md: "10", sm: "0", xs: "0" }}
            spacing={{ xxl: "16", xl: "16", md: "16", sm: "0", xs: "0" }}
            rowSpacing={{ xxl: "0", xl: "0", md: "0", sm: "16", xs: "16" }}
            className="GridContainer"
          >
            <Grid
              item
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  textAlign: "right",
                },
              }}
            >
              <TextField
                label="First Name"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  style: { color: "#BB9844" },
                }}
                focused
                fullWidth
                value={fName}
                name="fName"
                onChange={(e) => setfName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#BB9844", // Border color when input is focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  textAlign: "left",
                },
              }}
            >
              <TextField
                label="Last Name"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  style: { color: "#BB9844" },
                }}
                focused
                fullWidth
                value={lName}
                name="lName"
                onChange={(e) => setlName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#BB9844", // Border color when input is focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  textAlign: "right",
                },
              }}
            >
              <TextField
                label="Phone"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  style: { color: "#BB9844" },
                }}
                focused
                fullWidth
                value={Phone}
                name="Phone"
                onChange={(e) => setPhone(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#BB9844", // Border color when input is focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  textAlign: "left",
                },
              }}
            >
              <TextField
                label="Email"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  style: { color: "#BB9844" },
                }}
                focused
                fullWidth
                value={Email}
                name="Email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#BB9844", // Border color when input is focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  JustifyContent: "center",
                },
              }}
            >
              <TextField
                label="Message"
                id="custom-css-outlined-input"
                InputLabelProps={{
                  style: { color: "#BB9844" },
                }}
                focused
                multiline
                fullWidth
                rows={5}
                value={Message}
                name="Message"
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#BB9844", // Border color when input is focused
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <br />
          <iframe
            className="ContactUsMap"
            style={{ marginLeft: "10px" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.146291125725!2d35.64737237516847!3d34.1170043731327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f5d247a68069b%3A0xa13b39eef31a0a4c!2sByblos%20Physio%20Clinic%20By%20Charbel%20Sfeir!5e0!3m2!1sen!2slb!4v1702122399054!5m2!1sen!2slb"
            width="250"
            height="300"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <br/> */}
        </div>
        <Button className="ContactUsButton" onClick={() => sendEmail()}>
          SUBMIT
        </Button>
      </Container>
    </GoogleReCaptchaProvider>
  );
}

export default ContactUs;
