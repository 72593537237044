import React, { useEffect, useState } from "react";
import AOS from "aos";
import "./AboutUs.scss";
import "aos/dist/aos.css";
// import LOGO from "../assets/clinicphotos/Clinic 3.jpg";
import { Button, Container, Grid, Typography } from "@mui/material";

function AboutUsSection() {
  const [ShowMore, setShowMore] = useState(false)
  const [Mobile, setMobile] = useState(false)


  function checkWindowWidth() {
    if(window.innerWidth > 900 && Mobile) {
      setMobile(false)
    } else if(window.innerWidth < 900 && !Mobile) {
      setMobile(true)
    }
  }
  
  // Initial check
  checkWindowWidth();
  
  // Add event listener for window resize
  window.addEventListener('resize', checkWindowWidth);

  useEffect(() => {
    AOS.init({
      offset: 50, // Adjust the offset (in pixels) from the top of the window for triggering animations
      duration: 2000, // Specify the duration of the animation in milliseconds
      easing: "ease-in-out", // Specify the easing function for the animation
      once: true, // Set to true to trigger animations only once (default is false)
      mirror: false, // Set to true for the animation to run in the opposite direction when scrolling up
      anchorPlacement: "top-bottom", //
    });
  }, []);
  



  return (
    <div>
      <div
        // style={{margin:"auto"}}
        // data-aos="flip-left"
        // data-aos-easing="ease-out-cubic"
        // data-aos-duration="1000"
        className="AboutUsTitle"
      >
        <Typography variant={window.innerWidth > 900 ? "h3" : "h4"}>About Us</Typography>
      </div>

      <Container>
      <Grid container spacing={2} className="ContentAndPicContainer">
        <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className="aboutUsText">
          <div
            className="container-Content"
            // data-aos="fade-right"
            // // data-aos-offset="500"
            // data-aos-duration="500"
          >
           {window.innerWidth > 900 ? <> Welcome to Byblos Physio Clinic, your trusted
            partner on the journey to optimal health and wellness. We are a
            dedicated team of experienced physiotherapists committed to
            delivering exceptional care. Our clinic is founded on the principles
            of integrity, professionalism, and a genuine passion for helping our
            clients achieve their health goals. Our mission is to provide
            compassionate and personalized physiotherapy services to enhance the
            quality of life for all individuals.
            {/* <br />
            <br />
            &nbsp;We understand that each individual is unique, and so is their
            path to recovery. Our patient-centric approach involves thorough
            assessments, evidence-based treatments, and tailored rehabilitation
            plans. Whether you're recovering from an injury, managing a chronic
            condition, or aiming to optimize your physical well-being, we are
            here to support you every step of the way. */}
            <br />
            <br />
            &nbsp;Byblos Physio Clinic is more than a place for rehabilitation;
            it's a partner in your wellness journey. we have crafted a medical
            and wellness haven that seamlessly blends the historical charm of
            Byblos with the healing power of physiotherapy. Our facility offers
            not only a breathtaking view of the Mediterranean but also a serene
            environment where healing begins.</>
            : <>Welcome to Byblos Physio Clinic, your trusted
            partner on the journey to optimal health and wellness. We are a
            dedicated team of experienced physiotherapists committed to
            delivering exceptional care. Our clinic is founded on the principles
            of integrity, professionalism, and a genuine passion for helping our
            clients achieve their health goals. Our mission{!ShowMore ? <> ...<Button className="ShowMoreButton" onClick={() => setShowMore(!ShowMore)}>Show more</Button></> : <> is to provide
            compassionate and personalized physiotherapy services to enhance the
            quality of life for all individuals.
            {/* <br />
            <br />
            &nbsp;We understand that each individual is unique, and so is their
            path to recovery. Our patient-centric approach involves thorough
            assessments, evidence-based treatments, and tailored rehabilitation
            plans. Whether you're recovering from an injury, managing a chronic
            condition, or aiming to optimize your physical well-being, we are
            here to support you every step of the way. */}
            <br />
            <br />
            &nbsp;Byblos Physio Clinic is more than a place for rehabilitation;
            it's a partner in your wellness journey. we have crafted a medical
            and wellness haven that seamlessly blends the historical charm of
            Byblos with the healing power of physiotherapy. Our facility offers
            not only a breathtaking view of the Mediterranean but also a serene
            environment where healing begins. <br/><Button className="ShowMoreButton" onClick={() => setShowMore(!ShowMore)}>Show less</Button></> }</> }
          </div>
        </div>
        </Grid>
        <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
          {/* <div
            data-aos="fade-left"
            //  data-aos-offset="500"
            data-aos-duration="500"
          > */}
          <div data-aos="fade-up"  data-aos-duration="500">
            <img src={"https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fclinicphotos%2FClinic%203.jpg?alt=media&token=9d9a28c6-b7b2-453a-9963-03d35e597314"} className="container-logo"></img>
          </div>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
}

export default AboutUsSection;
