import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Faq.scss';

function Faq() {
  return (
    <Container className='faqContainer'>
      <div className="technologiesTitle">
        <br/>
        <Typography variant={window.innerWidth > 900 ? "h3" : "h4"}>FAQs</Typography>
        <br/>
      </div>

      <Accordion>
        <AccordionSummary  className='pointer'
          expandIcon={<ExpandMoreIcon  className='pointer color' />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>What conditions can physiotherapy treat?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Physiotherapy can address a wide range of conditions, including musculoskeletal issues, sports injuries, neurological disorders, respiratory problems, and more. It aims to enhance mobility, reduce pain, and improve overall well-being.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary  className='pointer'
          expandIcon={<ExpandMoreIcon  className='pointer color' />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How many sessions of physiotherapy might be needed?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The number of sessions varies based on the individual's condition and response to treatment. Your physiotherapist will assess your progress and recommend an appropriate treatment plan, adjusting as needed.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className='pointer'
          expandIcon={<ExpandMoreIcon className='pointer color' />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>Is physiotherapy painful?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Physiotherapy is designed to alleviate pain, not cause it. While some discomfort may be felt during certain exercises or manual techniques, it should not be excessive. Your physiotherapist will work with you to ensure your comfort and adjust treatments accordingly.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className='pointer'
          expandIcon={<ExpandMoreIcon  className='pointer color'/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>Can I do physio exercises at home?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, many physio exercises can be performed at home as part of your prescribed home exercise program. Your physiotherapist will provide clear instructions, monitor your progress, and make adjustments as necessary during follow-up sessions.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className='pointer'
          expandIcon={<ExpandMoreIcon  className='pointer color'/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How do I choose the right physiotherapist for me?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When selecting a physiotherapist, consider their qualifications, experience, and specialization in treating your specific condition. It's essential to communicate openly with your therapist, ensuring they understand your goals and preferences for a personalized treatment approach.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br/>
      <br/>
    </Container>
  );
}

export default Faq;
