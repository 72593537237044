import React , { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import './Card.scss';

export default function CustomCard({title , text , PicUrl}) {
  const [Show, setShow] = useState(false)
  const [Mobile, setMobile] = useState(false)


  function checkWindowWidth() {
    if(window.innerWidth > 750 && Mobile) {
      setMobile(false)
    } else if(window.innerWidth < 750 && !Mobile) {
      setMobile(true)
    }
  }
  
  // Initial check
  checkWindowWidth();
  
  // Add event listener for window resize
  window.addEventListener('resize', checkWindowWidth);

useEffect(() => {
  if (text.length > 100) {
    setShow(false)
  } else {
    setShow(true)
  }
}, [])


  return (
    <Card sx={{ maxWidth: 345}} className='cardContainer'>
      {/* <CardActionArea> */}
        {/* <CardMedia
          component="img"
          height="200"
          // image={PicUrl}
          style={{backgroundImage: `url(${PicUrl})` }}
          // alt="green iguana"
          className='CardMediaImage'
        /> */}
        <img src={PicUrl} className='CardMediaImage' />
        <CardContent sx={{paddingBottom: "0"}} className='cardContent'>
          <Typography gutterBottom variant="h5" component="div">
            {Show || Mobile ? title : title.substring(0 , 25) + "..."}
          </Typography>
          <Typography variant="body2" color="text.secondary" className='cardText'>
            {/* {text} */}
            {Show ? text : text.substring(0 , 100) + "..."}
          </Typography>
        </CardContent>
      {/* </CardActionArea> */}
      <CardActions>
        {/* <Button size="small">Share</Button> */}
        <Button size="medium" className='cardLearnMore' onClick={()=> setShow(!Show)}>{Show ? "Show less" : "Show More"}</Button>
      </CardActions>
    </Card>
  );
}