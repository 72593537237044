// Quote.js

import React from 'react';
import './Quote.scss';
import { Container, Typography } from '@mui/material';

function Quote() {
    return (
            <Typography variant="h5" className='quoteText'>
        {/* <Container className='quoteContainerComponent'> */}
                {/* Witness the magic of transformation through our{window.innerWidth < 900 ? <br/> : null} <b>physiotherapy</b>,<br/> where each success story shines with the brilliance of renewed health. */}
                Witness the magic of transformation<br/> through our{window.innerWidth < 900 ? <br/> : null} <b>physiotherapy</b><br/>
        {/* </Container> */}
            </Typography>
    );
}

export default Quote;
