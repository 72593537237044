import { Container, Typography } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, EffectCoverflow, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import CervicalTraction from '../../assets/clinictreatments/CervicalTraction.jpg';
// import Electrotherapy from '../../assets/clinictreatments/Electrotherapy.jpg';
// import Pressotherapy from '../../assets/clinictreatments/Pressotherapy.jpg';
// import shockWaveTherapy from '../../assets/clinictreatments/shockWaveTherapy.jpg';
// import Thermotherapy from '../../assets/clinictreatments/Thermotherapy.jpg';
import './Technologies.scss';

const Technologies = () => {

  return (
    <Container className='technologiesContainer'>
      <div className='technologiesTitle'>
        <br />
        <Typography variant={window.innerWidth > 900 ? 'h3' : 'h4'}>
          Our Technologies
        </Typography>
        <br />
      </div>
      <Swiper
        effect={'coverflow'}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        // keyboard={{
        //   enabled: true,
        // }}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        // coverflowEffect={{
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: true,
        // }}
        pagination={true}
        modules={[EffectCoverflow, Autoplay]}
        // className='mySwiper'
      >
        <SwiperSlide key={1}>
          <img src={"https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fclinictreatments%2FThermotherapy.jpg?alt=media&token=8393fe1a-dda9-4101-89a9-4d49e1f700e5"} style={{height: "100%" , width: "100%"}}/>
          <Typography className='overlay'>THERMOTHERAPY</Typography>
          {/* <Typography  className='overlayHover'>Rejuvenate with Thermotherapy! Our controlled heat applications enhance muscle flexibility, reduce tension, and promote overall well-being. 
Experience a personalized, comforting approach for a rejuvenated and refreshed you.</Typography> */}

        </SwiperSlide>
        <SwiperSlide key={2}> 
          <img src={"https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fclinictreatments%2FElectrotherapy.jpg?alt=media&token=c824f032-e97c-4d58-bb2b-b0a496f0092c"} style={{height: "100%" , width: "100%"}}/>
          <Typography className='overlay'>ELECTRO-THERAPY</Typography>
          {/* <Typography  className='overlayHover'>Energize your well-being with Electro-Therapy! This cutting-edge technology employs controlled electrical impulses to stimulate muscles, alleviate pain, 
and enhance overall health.</Typography> */}
        </SwiperSlide>
        <SwiperSlide key={3}>
          <img src={"https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fclinictreatments%2FCervicalTraction.jpg?alt=media&token=d0af07c4-58be-4156-957e-2fcb92922453"} style={{height: "100%" , width: "100%"}}/>
          <Typography className='overlay'>TRACTION THERAPY</Typography>
          {/* <Typography  className='overlayHover'>Relieve with Traction Therapy! Our gentle, non-invasive approach decompresses the spine, eases disc pressure, and enhances mobility.
Personalized for you, it's a soothing path to spinal health and well-being.</Typography> */}
        </SwiperSlide>
        <SwiperSlide key={4}> 
          <img src={"https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fclinictreatments%2FPressotherapy.jpg?alt=media&token=f90c0fb5-04ca-4096-9a37-41b382e70bbb"} style={{height: "100%" , width: "100%"}}/>
          <Typography className='overlay'>PRESSO-THERAPY</Typography>
          {/* <Typography  className='overlayHover'>Revitalize with our Presso-Therapy. Immerse yourself in a luxurious experience as controlled compression fosters improved circulation and reduces swelling, 
leaving you feeling refreshed and invigorated.
</Typography> */}
        </SwiperSlide>
        <SwiperSlide key={4}> 
          <img src={"https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fclinictreatments%2FshockWaveTherapy.jpg?alt=media&token=c54dc12a-e8ef-4777-a4e4-6599268528b1"} style={{height: "100%" , width: "100%"}}/>
          <Typography className='overlay'>SHOCKWAVE THERAPY</Typography>
          {/* <Typography  className='overlayHover'>Revitalize with our Presso-Therapy. Immerse yourself in a luxurious experience as controlled compression fosters improved circulation and reduces swelling, 
leaving you feeling refreshed and invigorated.
</Typography> */}
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Technologies;
