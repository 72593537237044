import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import "./Services.scss";
import CustomCard from '../../components/card/CustomCard';
import Thermotherapy from "../../assets/clinictreatments/Thermotherapy.jpg";
import Electrotherapy from "../../assets/clinictreatments/Electrotherapy.jpg";
import CervicalTraction from "../../assets/clinictreatments/CervicalTraction.jpg";
import Pressotherapy from "../../assets/clinictreatments/Pressotherapy.jpg";
import CuppingTherapy from "../../assets/clinictreatments/CuppingTherapy.jpg";
import iconsTest from "../../assets/sfeirIcons/assistant.png";
import therapyPic from "../../assets/sfeirIcons/therapy.png";
import iconsTest2 from "../../assets/sfeirIcons/clinic.png";
import healthcare from "../../assets/sfeirIcons/healthcare.png";
import physiotherapy from "../../assets/sfeirIcons/physiotherapy.png";
import tool from "../../assets/sfeirIcons/tool.png";
import woman from "../../assets/sfeirIcons/woman.png";
import treatment from "../../assets/sfeirIcons/treatment.png";
import medical from "../../assets/sfeirIcons/medical.png";
import pregnant from "../../assets/sfeirIcons/pregnant.png";
import clinic from "../../assets/sfeirIcons/clinic.png";
import feet from "../../assets/sfeirIcons/feet.png";
// import iconsTest2 from "../../assets/sfeirIcons/clinic.png";


function Services() {

  const [viewMore, setviewMore] = useState(false)

  return (
    <Container className='servicesContainer'>
      <div className="ServicesTitle">
      <br/>
      <Typography variant={window.innerWidth > 900 ? "h3" : "h4"}>Our Services</Typography>
      <br/>
      </div>
      <Container className='servicesContainer'>
        <Box className='servicesContainerBox'>
          <CustomCard
            title="Pain management"
            text="Discover effective relief with our pain management services. Our personalized approach combines targeted therapies and exercises to alleviate discomfort,
 promoting a more comfortable and active lifestyle."
            PicUrl={medical}
          />

        </Box>
        <Box className='servicesContainerBox'>
          <CustomCard
            title="Trigger point and medical massage therapies"
            text="Indulge in relaxation and relief with our trigger point and medical massage therapies. Our skilled practitioners use specialized techniques 
to target muscle tension and promote overall well-being, providing a rejuvenating experience tailored to your needs."
            PicUrl={physiotherapy}
          />
        </Box>
        <Box className='servicesContainerBox'>
        <CustomCard
            title="Traumatic and post surgery rehabilitation"
            text="Accelerate recovery with our specialized traumatic and post-surgery rehabilitation services. 
Our personalized programs focus on restoring function and enhancing well-being, providing expert care tailored to your unique needs."
            PicUrl={healthcare}
          />
        </Box>
        <Box className='servicesContainerBox'>
        <CustomCard
            title="Sports medicine"
            text="Elevate your athletic performance with our sports medicine services, designed to address injuries and optimize recovery
 through personalized treatments and exercise plans."
            PicUrl={therapyPic}
          />
        </Box>
        <Box className='servicesContainerBox'>
        <CustomCard
            title="Neurological rehabilitation"
            text="Empower your journey to recovery with our neurological rehabilitation services. Our personalized therapies and exercises are designed to enhance 
well-being and promote functional independence for individuals with neurological conditions."
            PicUrl={treatment}
          />
        </Box>
        <Box className='servicesContainerBox'>
        <CustomCard
            title="Migraines treatment"
            text="Discover effective relief with our personalized migraine treatment services, designed to help you regain control and enhance your quality of life."
            PicUrl={tool}
          />
        </Box>
        { viewMore ? <>
        {/* <Box className='servicesContainerBox'>
        <CustomCard
            title="Constipation treatment"
            text="Unlock relief with our personalized constipation treatment services, dedicated to promoting digestive well-being and optimizing bowel health for 
a more comfortable lifestyle."
            PicUrl={woman}
          />
        </Box> */}
        <Box className='servicesContainerBox'>
        <CustomCard
            title="Pre & Post pregnancy rehabilitation"
            text="Enhance maternal well-being with our pre and post-pregnancy rehabilitation services. Our specialized care includes tailored exercises and therapies to
 support women during and after pregnancy, promoting strength, flexibility, and optimal recovery."
            PicUrl={pregnant}
          />
        </Box>
        <Box className='servicesContainerBox'>
        <CustomCard
            title="Geriatric physiotherapy"
            text="Supporting seniors' strength & mobility one step at a time. Discover our personalized geriatric physiotherapy services today!"
            PicUrl={clinic}
          />
        </Box>
        {/* <Box className='servicesContainerBox'>
        <CustomCard
            title="Lymphatic Drainage"
            text="Revitalize your well-being with our lymphatic drainage therapy. Our specialized techniques gently stimulate the natural flow of lymphatic fluid,
 promoting toxin removal and supporting immune function for a rejuvenated and balanced lifestyle."
            PicUrl={feet}
          />
        </Box> */}
        </> : null }
        <Container>
        {viewMore ? <Button variant="contained" className="ShowMoreButton" onClick={() => setviewMore(false)}>
                Show Less
              </Button>:    <Button variant="contained" className="ShowMoreButton" onClick={() => setviewMore(true)}>
                Show More
              </Button>}
              </Container>
      </Container>

      {/* <br/> */}
      <br/>
    </Container>
  );
}

export default Services