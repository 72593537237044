import react , {useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './Navbar.scss'
import ClinicLogo from '../assets/logos/image-removebg.png'
import { Link } from 'react-router-dom';


const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

function DrawerAppBar({showForContactus}) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [show, handleShow] = useState(false);
  // const [showForContactus, handleShowContactUs] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if(window.innerWidth > 1000) {
      if (window.scrollY > 500) {
        handleShow(true);
      } else handleShow(false);
    } else {
      if (window.scrollY > 200) {
        handleShow(true);
      } else handleShow(false);
    }
  });
    return () => {
      window.removeEventListener("scroll" , () => handleShow(false));
    };
  }, []);

  

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' , marginTop: '50px' }} className="MobileDrawer">
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUIs
      </Typography>
      <Divider /> */}
      <List className="MobileDrawer">
        {/* {navItems.map((item) => ( */}
        <Link to="/" className="NavLink">
          <ListItem key={'Home'} disablePadding>
            <ListItemButton sx={{ textAlign: 'center'}} className='ButtonsOfMobileDrawer'>
              <ListItemText primary={'Home'}  />
            </ListItemButton>
          </ListItem>
          </Link>
          {/* <ListItem key={'About'} disablePadding>
            <ListItemButton sx={{ textAlign: 'center'}} className='ButtonsOfMobileDrawer'>
              <ListItemText primary={'About'}  />
            </ListItemButton>
          </ListItem> */}
        <Link to="/contact-us" className="NavLink">
          <ListItem key={'Contact'} disablePadding>
            <ListItemButton sx={{ textAlign: 'center'}} className='ButtonsOfMobileDrawer'>
              <ListItemText primary={'Contact'}  />
            </ListItemButton>
          </ListItem>
          </Link>
        {/* ))} */}
      </List>
    </Box>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav"
      className= {show || showForContactus ? 'notTransparent' : 'transparentbg'}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon className='burgerMenu' />
          </IconButton>
          <Box sx={{flexGrow: 1 , textAlign:'left', height: "min-content" , padding: '0px !important'}}>
            <img className='ClinicLogo' src={ClinicLogo}></img>
            </Box>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block',lg: 'block',xl: 'block' } }}>
            {/* {navItems.map((item) => ( */}
            <Link to="/" className="NavLink">
              <Button key={"Home"} className='ButtonItems' >
               <b> Home</b>
              </Button>
              </Link>
              {/* <Button key={"About"} className='ButtonItems'>
               <b> About</b>
              </Button> */}
            <Link to="/contact-us" className="NavLink">
              <Button key={"Contact"} className='ButtonItems'>
               <b> Contact</b>
              </Button>
              </Link>
            {/* ))} */}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;