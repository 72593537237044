import React from 'react'
import './Footer.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Footer() {
  return (
    <div>
  <footer className="footer">
    <div className="waves">
      <div className="wave" id="wave1"></div>
      <div className="wave" id="wave2"></div>
      <div className="wave" id="wave3"></div>
      <div className="wave" id="wave4"></div>
    </div>
    <ul className="social-icon">
      <li className="social-icon__item">
      <a href="https://wa.me/+96170396957" className="SocialAnchorsFooter">
                <WhatsAppIcon fontSize="large"  className="SocialICONFooter"/>
            </a>
      </li>
      <li className="social-icon__item">  <a
                href="https://www.facebook.com/ByblosPhysioClinic?mibextid=ZbWKwL"
                target="_blank"
                className="SocialAnchorsFooter">
                <FacebookIcon fontSize="large"  className="SocialICONFooter"/>
              </a></li>
      <li className="social-icon__item">      <a
                href="https://www.instagram.com/byblosphysioclinic/"
                target="_blank"
                className="SocialAnchorsFooter">
                <InstagramIcon fontSize="large"  className="SocialICONFooter"/>
              </a></li>
      <li className="social-icon__item"><a href="mailto:byblosphysioclinic@gmail.com" className="SocialAnchorsFooter">
                <MailIcon fontSize="large"  className="SocialICONFooter"/>
                <span></span>
              </a></li>
    </ul>
    {/* <ul class="menu">
      <li class="menu__item"><a class="menu__link" href="#">Home</a></li>
      <li class="menu__item"><a class="menu__link" href="#">About</a></li>
      <li class="menu__item"><a class="menu__link" href="#">Services</a></li>
      <li class="menu__item"><a class="menu__link" href="#">Team</a></li>
      <li class="menu__item"><a class="menu__link" href="#">Contact</a></li>

    </ul> */}
    <p>&copy;{new Date().getFullYear()} Dr. Charbel Sfeir | All Rights Reserved</p>
  <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
  <script noModule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
  </footer>
</div>
  )
}
    {/* <div>Have questions or need assistance? Feel free to reach out to us on any of these platforms.</div> */}

export default Footer