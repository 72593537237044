const axios = require('axios');

exports.handler = async (event) => {
    const { token } = JSON.parse(event.body);

    const secret = '6Lf2xKUpAAAAABIvkR0nw6TVjic7o84ieIL9GCJi';
    const url = `https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`;

    try {
        const response = await axios.post(url);
        const { success } = response.data;
        if (success) {
            return {
                statusCode: 200,
                body: JSON.stringify({ message: 'reCAPTCHA verification successful' })
            };
        } else {
            return {
                statusCode: 403,
                body: JSON.stringify({ error: 'reCAPTCHA verification failed' })
            };
        }
    } catch (error) {
        return {
            statusCode: 500,
            body: JSON.stringify({ error: 'Internal server error' })
        };
    }
};
